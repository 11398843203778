const host = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://" + window.location.hostname; 
 
const awsmobile = { 
    'aws_project_region': 'eu-west-1', 
    'aws_cognito_identity_pool_id':'eu-west-1:1c3f1a54-e3ea-46d8-ae28-b118cd82e367', 
    'aws_cognito_region': 'eu-west-1', 
    'aws_user_pools_id': 'eu-west-1_DcCbr4ubw', 
    'aws_user_pools_web_client_id': 'cbde9tc0ihrosi5pscppl0dev', 
    'oauth': { 
    'domain': 'tika-beta.auth.eu-west-1.amazoncognito.com', 
    'scope': ['profile', 'openid', 'aws.cognito.signin.user.admin'], 
    'redirectSignIn': window.location.protocol + '//' + window.location.host, 
    'redirectSignOut': window.location.protocol + '//' + window.location.host, 
    'responseType': 'code', 
    }, 
    'federationTarget': 'COGNITO_USER_POOLS',
    'aws_mobile_analytics_app_id': '8b4f543190d244dfad7b676c2771639e',
    'aws_mobile_analytics_app_region': 'eu-west-1',    
    'API': { 
        'endpoints': [ 
            { 
            'name': 'TikaAPI', 
            'endpoint': 'https://api.beta.tika.sa.aws.dev/', 
            } 
        ]
    },
    'defaultRole': '98e01c1b-d4b6-4c84-bb57-504479f9dc65'
}

export default awsmobile;
